<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Upload Documents - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="false"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="true"
                />
            </v-col>
        </v-row>

        <v-expansion-panels popout>

            <!-- DRIVE`S LICENSE -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-card-account-details-outline
                                </v-icon>
                                Drive`s License
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Drive`s License</label>
                                <v-file-input
                                    v-model="driveLicenseListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(driveLicenseListFiles, driveLicenseFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in driveLicenseFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(driveLicenseFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- PERMIT APPLICATION -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-license
                                </v-icon>
                                Permit Application
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Permit Application</label>
                                <v-file-input
                                    v-model="permitApplicationListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(permitApplicationListFiles, permitApplicationFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in permitApplicationFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(permitApplicationFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- SPECS -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-file-document-plus
                                </v-icon>
                                Specs
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Spec</label>
                                <v-file-input
                                    v-model="specsListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(specsListFiles, specsFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in specsFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(specsFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- SOIL REPORT -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-floor-plan
                                </v-icon>
                                Soil Report (If Applicable)
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Soil Report</label>
                                <v-file-input
                                    v-model="soilReportListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(soilReportListFiles, soilReportFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in soilReportFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(soilReportFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- CHECK DOCUMENTS -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-all
                                </v-icon>
                                Check Documents
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                                style="margin-bottom: 10px;"
                            >
                                <label>Survey</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="surveyFile.id != 0"
                                    max-width="300"
                                    style="text-align: center;"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, surveyFile.id, 'application/pdf', surveyFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ surveyFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ surveyFile.fileName }}</span>
                                </v-card>
                            </v-col>
                            <v-col
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <v-btn-toggle
                                    v-model="surveyStatus"
                                >
                                    <v-btn 
                                        @click="surveyStatus = 0" 
                                        class="btnToogleDenied"
                                    >
                                        <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                    </v-btn>

                                    <v-btn 
                                        @click="surveyStatus = 1"
                                        class="btnToogleApproved"
                                    >
                                        <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                                style="margin-bottom: 10px; margin-top: 40px;"
                            >
                                <label>Pool Plan Signed By Engineering</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="poolPlanSignedFile.id != 0"
                                    max-width="300"
                                    style="text-align: center;"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, poolPlanSignedFile.id, 'application/pdf', poolPlanSignedFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ poolPlanSignedFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ poolPlanSignedFile.fileName }}</span>
                                </v-card>
                            </v-col>
                            <v-col
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <v-btn-toggle
                                    v-model="poolPlanSignedStatus"
                                >
                                    <v-btn 
                                        @click="poolPlanSignedStatus = 0" 
                                        class="btnToogleDenied"
                                    >
                                        <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                    </v-btn>

                                    <v-btn 
                                        @click="poolPlanSignedStatus = 1"
                                        class="btnToogleApproved"
                                    >
                                        <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                                style="margin-bottom: 10px; margin-top: 40px;"
                            >
                                <label>Load Calculation Signed By Engineering</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="loadCalculationSignedFile.id != 0"
                                    max-width="300"
                                    style="text-align: center;"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, loadCalculationSignedFile.id, 'application/pdf', loadCalculationSignedFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ loadCalculationSignedFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ loadCalculationSignedFile.fileName }}</span>
                                </v-card>
                            </v-col>
                            <v-col
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <v-btn-toggle
                                    v-model="loadCalculationSignedStatus"
                                >
                                    <v-btn 
                                        @click="loadCalculationSignedStatus = 0" 
                                        class="btnToogleDenied"
                                    >
                                        <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                    </v-btn>

                                    <v-btn 
                                        @click="loadCalculationSignedStatus = 1"
                                        class="btnToogleApproved"
                                    >
                                        <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

        </v-expansion-panels>

        <div 
            class="text-center"
        >
            <v-row
                style="padding-top: 50px"
            >
                <v-col 
                    style="text-align: right"
                    cols="6"
                    lg="5"
                    md="4"
                    sm="6"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                @click="cancel"
                                style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            >
                                Cancel
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                </v-col>
                <v-col 
                    style="text-align: center"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="save"
                            >
                                Save
                            </v-btn>
                        </template>
                        <span>Save and continue later</span>
                    </v-tooltip>
                </v-col>
                
                <v-col 
                    style="text-align: left"
                    cols="12"
                    lg="5"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                @click="validateAndFinish"
                            >
                                Move to next step
                            </v-btn>
                        </template>
                        <span>Finish and move to Ready to submit</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        URL_ATTACHMENT, 
        PROJECT_STATUS
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            driveLicenseListFiles: [],
            driveLicenseFiles: [],

            permitApplicationListFiles: [],
            permitApplicationFiles: [],

            specsListFiles: [],
            specsFiles: [],

            soilReportListFiles: [],
            soilReportFiles: [],

            surveyFile: { 
                id: 0,
                fileName: '', 
                type: 'application/pdf' 
            },
            surveyStatus: null,

            poolPlanSignedFile: { 
                id: 0,
                fileName: '', 
                type: 'application/pdf' 
            },
            poolPlanSignedStatus: null,

            loadCalculationSignedFile: { 
                id: 0,
                fileName: '', 
                type: 'application/pdf' 
            },
            loadCalculationSignedStatus: null,
            
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            PROJECT_STATUS
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let fileName = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                fileName = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: 0,
                                file: itemFile,
                                fileName: fileName,
                                deleted: false,
                            }

                            files.push(item);
						}	
					});
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }

                    this.driveLicenseListFiles = [];
                    this.driveLicenseFiles = [];

                    this.permitApplicationListFiles = [];
                    this.permitApplicationFiles = [];

                    this.specsListFiles = [];
                    this.specsFiles = [];

                    this.soilReportListFiles = [];
                    this.soilReportFiles = [];
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: this.id }); 

                    const driveLicenseListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_DRIVES_LICENSE.value);
                    if (driveLicenseListFiles != null && driveLicenseListFiles != undefined) {

                        driveLicenseListFiles.forEach(itemFile => {

                            this.driveLicenseFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const permitApplicationListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_PERMIT_APPLICATION.value);
                    if (permitApplicationListFiles != null && permitApplicationListFiles != undefined) {

                        permitApplicationListFiles.forEach(itemFile => {

                            this.permitApplicationFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const specsListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_SPECS.value);
                    if (specsListFiles != null && specsListFiles != undefined) {

                        specsListFiles.forEach(itemFile => {

                            this.specsFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const soilReportListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_SOIL_REPORT.value);
                    if (soilReportListFiles != null && soilReportListFiles != undefined) {

                        soilReportListFiles.forEach(itemFile => {

                            this.soilReportFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }
                    
                    listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: this.id });

                    //SURVEY
                    const itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];
                    if (itemSessionDetailSurveyType != null && itemSessionDetailSurveyType != undefined) {
                        this.surveyFile.id = itemSessionDetailSurveyType.id;
                        this.surveyFile.fileName = itemSessionDetailSurveyType.fileName;
                        this.surveyFile.type = itemSessionDetailSurveyType.type;
                    }

                    const responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", this.id);

                    if (responseDraftingUploadFiles.result.id != 0) {
                    
                        listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.DRAFTING.value, idKey: responseDraftingUploadFiles.result.id });

                        //POOL PLAN SIGNED BY ENGINEERING
                        const poolPlanSignedFile = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_POOL_PLAN_ENGINEER_SIGNED.value)[0];
                        if (poolPlanSignedFile != null && poolPlanSignedFile != undefined) {
                            this.poolPlanSignedFile.id = poolPlanSignedFile.id;
                            this.poolPlanSignedFile.fileName = poolPlanSignedFile.fileName;
                            this.poolPlanSignedFile.type = poolPlanSignedFile.type;
                        }

                        //LOAD CALCULATION SIGNED BY ENGINEERING
                        const loadCalculationSignedFile = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_LOAD_CALCULATION_SIGNED.value)[0];
                        if (loadCalculationSignedFile != null && loadCalculationSignedFile != undefined) {
                            this.loadCalculationSignedFile.id = loadCalculationSignedFile.id;
                            this.loadCalculationSignedFile.fileName = loadCalculationSignedFile.fileName;
                            this.loadCalculationSignedFile.type = loadCalculationSignedFile.type;
                        }
                    }
                }
            },
            
            async confirmFileDelete(listFile, itemFileToDelete) {

                this.listFileDelete = listFile;
                this.itemFileToDelete = itemFileToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemFileToDelete.fileName}], confirm your decision?`,
                    methodCancelOrDeny: () => {},
                    methodConfirm: this.deleteFile,
                    dismissDialog: true,
                    params: 0
                };
            },

            async deleteFile() {

                this.itemFileToDelete.deleted = true;
            },

            async cancel() {
                this.$router.push({ path: "/permitting/pipeline" });
            },

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.driveLicenseFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_DRIVES_LICENSE.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERMITTING_DRIVES_LICENSE.description,
                            message: result.message
                        })
                    }
                })

                this.permitApplicationFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_PERMIT_APPLICATION.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERMITTING_PERMIT_APPLICATION.description,
                            message: result.message
                        })
                    }
                })

                this.specsFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_SPECS.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERMITTING_SPECS.description,
                            message: result.message
                        })
                    }
                })

                this.soilReportFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_SOIL_REPORT.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERMITTING_SOIL_REPORT.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async validateCheckItems() {

                let result = {
                    success: true
                };

                if (this.driveLicenseFiles == null || this.driveLicenseFiles == undefined || this.driveLicenseFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Drive´s License File.`);
                }
                else if (this.permitApplicationFiles == null || this.permitApplicationFiles == undefined || this.permitApplicationFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Permit Application File.`);
                }
                else if (this.specsFiles == null || this.specsFiles == undefined || this.specsFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Specs File.`);
                }
                else if (this.surveyStatus == null || this.surveyStatus == undefined || this.surveyStatus == 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must check and approve survey document.`);
                }
                else if (this.poolPlanSignedStatus == null || this.poolPlanSignedStatus == undefined || this.poolPlanSignedStatus == 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must check and approve pool plan signed by engineering document.`);
                }
                else if (this.loadCalculationSignedStatus == null || this.loadCalculationSignedStatus == undefined || this.loadCalculationSignedStatus == 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must check and approve load calculation signed by engineering document.`);
                }

                return result;
            },

            async validateAndFinish() {

                let result = await this.validateCheckItems();

                if (result.success)
                {
                    let message = "You will finish UPLOAD DOCUMENTS and go to READY TO SUBMIT step, confirm your decision?";

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: message,
                        methodConfirm: () => { this.save(true); },
                        params: 0
                    };
                }
            },

            async save(finishProcess) {

                this.showLoading();

                if (await this.saveFiles()) {

                    this.showToast("success", "Success!", "Documents uploaded successfully!");

                    if (finishProcess == true) {

                        let updateStatusRequest = {
                            id: this.id,
                            status: this.PROJECT_STATUS.PERMITTING_READY_SUBMIT
                        };

                        await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                    }

                    this.cancel();
                }
                this.hideLoading();
            },
        },

        async created() {

            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }
    
    .v-file-input > .v-input__control {
        height: 55px !important;
    }

    .cardDownload {
        text-align: center;
    }
</style>